<div (click)="goToDetails()"
    class="w-full flex p-[28px] rounded-[10px] bg-neutral-0 border border-neutral-100 box-shadow h-full cursor-pointer">
    <div class="w-full flex flex-col justify-between gap-3">
        <div class="flex flex-row items-center justify-between gap-3">
            <span
                class="bg-[#EFEFFF] text-neutral-600 text-[10px] font-normal leading-[14px] px-[10px] py-1 rounded-md overflow-hidden whitespace-nowrap text-ellipsis">
                {{journey?.name}}
            </span>
            <span class="text-12 font-medium text-neutral-600 leading-[14px] whitespace-nowrap">SKU: {{sku}}</span>
        </div>

        <div class="flex flex-col gap-1 flex-1">
            <span
                class="text-18 text-neutral-700 font-semibold leading-[25px] overflow-hidden whitespace-nowrap text-ellipsis">{{title}}</span>
            <span class="text-12 text-neutral-700 leading-[19.5px] overflow-hidden whitespace-nowrap text-ellipsis">
                {{description | removeHtmlTag}}
            </span>
        </div>

        <div class="flex flex-row items-center justify-between">
            @if(minTime || minPrice){
            <div class="flex flex-col gap-1">
                <div class="flex flex-row gap-1">
                    <img src="assets/icons/fastest.svg" alt="">
                    <span class="text-11 text-neutral-600 font-medium">Fastest</span>
                </div>
                <span class="text-neutral-700 text-14  font-semibold">
                    <span class="mr-[14px]">
                        {{minTime ? minTime : time}}
                        {{minTime === 1 ? 'day' : 'days'}}
                    </span>
                    <span>
                        {{price / 100 | currency : currency.value : 'symbol' : '1.0-0' | space}}
                    </span>
                </span>
            </div>
            <div class="flex flex-col gap-1">
                <div class="flex flex-row gap-1">
                    <img src="assets/icons/cheapest.svg" alt="">
                    <span class="text-11 text-neutral-600 font-medium">Lowest cost</span>
                </div>
                <span class="text-neutral-700 text-14 font-semibold">
                    <span class="mr-[14px]">
                        {{time}}
                        {{time === 1 ? 'day' : 'days'}}
                    </span>
                    <span>
                        @if(minPrice){
                        {{ minPrice / 100 | currency : currency.value: 'symbol' : '1.0-0' | space }}
                        }@else{
                        {{price / 100 | currency : currency.value : 'symbol' : '1.0-0' | space}}
                        }
                    </span>
                </span>
            </div>
            }@else {
            <div class="flex flex-col gap-1">
                <div class="flex flex-row gap-1">
                    <img src="assets/icons/doneIn.svg" alt="">
                    <span class="text-11 text-neutral-600 font-medium">Done in</span>
                </div>
                
                <span class="text-neutral-700 text-14  font-semibold">
                    <span class="mr-[14px]">
                        {{time}}
                        {{time === 1 ? 'day' : 'days'}}
                    </span>
                    <span>
                        {{price / 100 | currency : currency.value : 'symbol' : '1.0-0' | space}}
                    </span>
                </span>
            </div>
            }
            <!-- <div class="flex flex-col gap-1">
                <span class="text-11 text-neutral-300">Duration</span>
                <span class="text-neutral-700 text-14  font-semibold">
                    {{minTime ? ( minTime + ' - ' ) : ''}}
                    {{time}}

                    {{time === 1 ? 'Day' : 'Days'}}
                </span>
            </div>
            <div class="flex flex-col gap-1 flex-1">
                <span class="text-11 text-neutral-300">Price</span>
                <span class="text-neutral-700 text-14 font-semibold">
                    @if(minPrice){
                    {{( minPrice / 100 | currency : currency.value: 'symbol' : '1.0-0' | space) + ' - ' + (price / 100 |
                    currency : '' : '' : '1.0-0')}}
                    }@else{
                    {{price / 100 | currency : currency.value : 'symbol' : '1.0-0' | space}}
                    }
                </span>
            </div> -->
        </div>
    </div>
</div>